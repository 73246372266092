<template>
  <div>
    <div class="container">
    <div class="left">
      <van-icon @click="$router. go(-1)" size=".35rem" name="arrow-left"/>
      <div class="wx">邮箱</div>
    </div>
    <div class="submit" @click="submit">保存</div>
  </div>
    <div class="email">
      <input v-model="email" type="email" placeholder="请输入您的邮箱">
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
export default {
   data(){
     return {
       email:""
     }
   },
   mounted(){
     let email = this.$route.query.email
     this.email = email
    // console.log(email)
   },
   methods:{
     submit(){
       let email = this.email
       if(email == ''){
         Toast("请输入您的邮箱")
         return
       }
       let reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
       let bool = reg.test(email)
      //  console.log(bool)
       if(bool == false){
          Toast("请输入正确邮箱")
         return
       }
       this.$http.post('/user/v1/personal_info/editPersonalInfo',{reqType:"editPersonalInfo",email:email})
       .then((res)=>{
         let response = JSON.parse(res.data)
         if(response.code == 0){
           Toast.success("保存成功")
           this.$router.push("/usermsg")
         }else{
           Toast.fail(response.msg)
         }
        
         })
       .catch((e)=>{console.log(e)})
     },
   }
}
</script>
<style scoped>
.email{
  height:1.13rem;
  line-height: 1.13rem;
  background: #FFFFFF;
  padding-left:0.49rem;
}
.email input{
  border:none;
  font-size:0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #848484;
line-height: 0.48rem;
height: 0.48rem;
}
.submit{
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.container{
  display:flex;
  justify-content: space-between;
  height:1rem;
  line-height: 1rem;
  background:#fff;
  padding:0 0.32rem;
  border:0.02rem solid #eee;
}
.left{
  display:flex;
  align-items: center;
}
.wx{
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left:0.3rem;
}
</style>